import { isTouch } from '../utils/helpers';

export function setupInvestmentList(selector = '.investment-list') {
    const investmentListModules = document.querySelectorAll(selector);

    if (investmentListModules && !isTouch) {

        investmentListModules.forEach(investmentListModule => {

            const investmentListItems = investmentListModule.querySelectorAll('.investment-list__item');

            if (investmentListItems) {

                investmentListItems.forEach(investmentListItem => {

                    const pictureSource = investmentListItem.getAttribute('data-picture-source'),
                        pictureTarget = investmentListModule.querySelector('[data-picture-target="'+pictureSource+'"]');

                    if (pictureTarget) {

                        investmentListItem.addEventListener('mouseenter', function() {
                            pictureTarget.classList.add('investment-list__image-wrap--active');
                        });

                        investmentListItem.addEventListener('mouseleave', function() {
                            pictureTarget.classList.remove('investment-list__image-wrap--active');
                        });
                    }
                });
            }
        });
    }
}
